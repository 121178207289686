module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ESCEMI - À votre service","short_name":"ESCEMI","description":"À votre service pour vous accompagner et relever les défis de vos projets digitaux","start_url":"/","background_color":"#1c3144","theme_color":"#1c3144","display":"standalone","lang":"fr","localize":[{"start_url":"/en/","lang":"en","name":"ESCEMI - At your service","short_name":"ESCEMI","description":"At your service to support you and meet the challenges of your digital projects"}],"icon":"src/assets/img/website-icon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3da83287d8730c4d3756b13d00a01d5e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.escemi.com","localeJsonSourceName":"locale","languages":["fr","en"],"defaultLanguage":"fr","i18nextOptions":{"nsSeparator":"::"},"pages":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
